import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, Collapse, Divider, IconButton, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Title } from '@mui/icons-material';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { camara, cidade, KEY_REF } from '../adm/admin/firebaseConfig';
import TitleSection from './TittleTheme';
import ArticleIcon from '@mui/icons-material/Article';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from 'react-router-dom';
import { items } from '../adm/admin/config';
import { getConfig } from '../adm/ConfigContext';



const SidebarNavigation = ({ noticia }) => {
      const config = getConfig()
    const [expanded, setExpanded] = useState(null);
    const [noticias, setNoticias] = useState([]);


    const handleToggle = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    useEffect(() => {
        const fetchNoticias = async () => {
            if (!noticia) return;
    
            const databaseInstance = getDatabase(firebaseApp);
            const noticiasRef = ref(databaseInstance, `noticias`);
    
            try {
                const snapshot = await get(noticiasRef);
                const data = snapshot.val();
    
                if (data) {
                    const noticiasArray = Object.keys(data).map((key) => ({
                        id: key,
                        titulo: data[key].titulo,
                        descricao: data[key].descricao,
                        imagem: data[key].imagem,
                    }));
    
                    const noticiasAleatorias = noticiasArray
                        .sort(() => Math.random() - 0.5)
                        .slice(0, 4);
    
                    setNoticias(noticiasAleatorias);
                }
            } catch (error) {
                console.error('Erro ao buscar notícias:', error);
            }
        };
    
        fetchNoticias();
    }, [noticia]);
    

    const renderNoticia = (noticiaItem, index) => (
        <React.Fragment key={index}>
            <ListItem button onClick={() => handleToggle(`noticia-${index}`)}>
                <ArticleIcon sx={{ fontSize: 20, mr: 1, color: config?.color }} />
                <ListItemText
                    primary={noticiaItem.titulo.length > 30
                        ? `${noticiaItem.titulo.slice(0, 50)}...`
                        : noticiaItem.titulo
                    }
                    primaryTypographyProps={{
                        variant: 'subtitle1',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: 'text.primary',
                    }}
                />
                <IconButton size="small">
                    {expanded === `noticia-${index}` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </ListItem>
            <Collapse in={expanded === `noticia-${index}`} timeout="auto" unmountOnExit>
                <Box sx={{ pl: 4, mb: 2 }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {noticiaItem.descricao.slice(0, 250)}...
                    </Typography>
                    <a
                        href={`/noticias/${noticiaItem.titulo.replace(/\s+/g, '-').toLowerCase()}/${noticiaItem.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            startIcon={<LaunchIcon />}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                textTransform: 'none',
                                padding: '4px 10px',
                                backgroundColor: config?.color
                            }}
                        >
                            Ler Notícia
                        </Button>
                    </a>
                </Box>
            </Collapse>
            <Divider />
        </React.Fragment>
    );

    return (
        <Box sx={{ flex: 1.5, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ width: "100%" }}>
                <TitleSection title={'Explorando'} subtitle={`Conheça mais sobre ${cidade}`} share={true} novo={true} />
            </Box>
            <Paper elevation={1} sx={{ p: 2 }}>
                {noticia && (
                    <>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: config?.color, mb: 2 }}>
                            Notícias em Destaque
                        </Typography>
                        <List component="nav">
                            {noticias.map((noticiaItem, index) => renderNoticia(noticiaItem, index))}
                        </List>
                        <Divider sx={{ my: 0 }} />
                    </>
                )}
                 {!camara && KEY_REF === '2506509' &&
                    <List component="nav">
                        {items.map((section, sectionIndex) => (
                            <React.Fragment key={sectionIndex}>
                                <ListItem>
                                    <ListItemText
                                        primary={section.category}
                                        primaryTypographyProps={{
                                            variant: 'h6',
                                            fontWeight: 'bold',
                                            color: config?.color,
                                        }}
                                    />
                                </ListItem>
                                <Divider />
                                {section.events.map((event, idx) => (
                                    <React.Fragment key={idx}>
                                        <ListItem button onClick={() => handleToggle(`${sectionIndex}-${idx}`)}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', color: 'text.primary' }}>
                                                <StarIcon sx={{ fontSize: 16, mr: 1, color: config?.color }} /> {event.name}
                                            </Typography>
                                            <IconButton size="small">
                                                {expanded === `${sectionIndex}-${idx}` ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </ListItem>
                                        <Collapse in={expanded === `${sectionIndex}-${idx}`} timeout="auto" unmountOnExit>
                                            <Box sx={{ pl: 4, mb: 2 }}>
                                                <Box
                                                    component="img"
                                                    src={event.imageUrl}
                                                    alt={event.name}
                                                    loading="lazy"
                                                    sx={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxHeight: 220,
                                                        borderRadius: 2,
                                                        objectFit: 'cover',
                                                        mb: 1,
                                                    }}
                                                />
                                                <Typography variant="caption" color="text.secondary">
                                                    {event.description}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                                                    <strong>Endereço:</strong> {event.address}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary" display="block">
                                                    <strong>Funcionamento:</strong> {event.openingHours}
                                                </Typography>
                                            </Box>
                                        </Collapse>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                                <Divider sx={{ my: 2 }} />
                            </React.Fragment>
                        ))}
                    </List>
                }

            </Paper>
        </Box>
    );
};

export default SidebarNavigation;