import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
  Button,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
  TextField,
  CircularProgress,
  InputAdornment,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { get, ref } from 'firebase/database';
import firebaseApp, { camara, cidade, database, KEY_REF, removeAccents } from '../adm/admin/firebaseConfig';

const Logo = styled('img')(({ theme }) => ({
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    width: '150px',
  },
}));

export default function Header({ config }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [secretarios, setSecretarios] = useState([]);
  const [vereadores, setVereadores] = useState([]);
  const [dynamicMenus, setDynamicMenus] = useState([]);
  const [subMenuOpen, setSubMenuOpen] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const iconSize = isVerySmallScreen ? 'small' : isSmallScreen ? 'medium' : 'default';
  const fontSize = isVerySmallScreen ? '0.7rem' : isSmallScreen ? '0.85rem' : '1rem';

  useEffect(() => {
    const fetchMenus = async () => {
      const menus = config.menus || [];
      // Normalizar os menus para garantir que items sempre exista
      const normalizedMenus = menus.map(menu => ({
        ...menu,
        items: Array.isArray(menu.items) ? menu.items : [],
        link: menu.link || "",
      }));
      setDynamicMenus(normalizedMenus);
    };
    fetchMenus();
  }, [config.menus]);

  useEffect(() => {
    if (camara) {
      const vereadoresRef = ref(database, `vereadores`);
      const fetchVereadores = async () => {
        try {
          const snapshot = await get(vereadoresRef);
          const data = snapshot.val();
          if (data) {
            const vereadoresArray = Object.keys(data)
              .map((key) => ({
                id: key,
                nome: data[key].nome,
              }))
              .sort((a, b) => a.nome.localeCompare(b.nome));
            setVereadores(vereadoresArray);
          } else {
            setVereadores([]);
          }
        } catch (error) {
          console.error("Erro ao buscar vereadores:", error);
        }
      };
      fetchVereadores();
    }

    if (!camara) {
      const secretariosRef = ref(database, `secretarios`);
      const fetchSecretarios = async () => {
        try {
          const snapshot = await get(secretariosRef);
          const data = snapshot.val();
          if (data) {
            const secretariosArray = Object.keys(data).map((key) => ({
              id: key,
              cargo: data[key].cargo,
              nome: data[key].nome,
            }));
            setSecretarios(secretariosArray);
          } else {
            setSecretarios([]);
          }
        } catch (error) {
          console.error("Erro ao buscar secretários:", error);
        }
      };
      fetchSecretarios();
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleSubMenu = (event, menu) => {
    if (isMobile) {
      setSubMenuOpen((prevState) => ({
        ...prevState,
        [menu]: !prevState[menu],
      }));
    } else {
      setAnchorEl(event.currentTarget);
      setSubMenuOpen({
        [menu]: true,
      });
    }
  };

  const closeSubMenu = () => {
    setAnchorEl(null);
    setSubMenuOpen({});
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      const headerLinks = [
        { path: "/", title: "Início", keywords: ["home", "pagina inicial", "inicio", "inicial", "começo"] },
        { path: "/prefeitura/prefeito", title: "Prefeito", keywords: ["gestor", "governante", "administrador", "líder", "prefeito"] },
        { path: "/prefeitura/viceprefeito", title: "Vice-Prefeito", keywords: ["vice", "sub-prefeito", "assistente", "co-gestor", "viceprefeito", "vice prefeito"] },
        { path: "/transparencia", title: "Transparência Fiscal", keywords: ["impostos", "contas", "financeiro", "tributos", "prestação de contas", "transparencia", "receita", "despesa", "covid", "convenio", "estagiario", "renuncia", "informacao"] },
        { path: "/app/notasfiscais", title: "NFS-E", keywords: ["nota fiscal", "notas fiscais", "notafiscal", "nfse", "emissão", "emissao", "emissao nf", "recibo fiscal"] },
        { path: "/app/perguntas", title: "Perguntas", keywords: ["perguntas", "pergunta"] },
        { path: "/ouvidoria", title: "Ouvidoria", keywords: ["ouvidoria", "ouvidoria publica", "reclamações", "sugestões", "solicitações", "denúncia", "contato ouvidoria"] },
        { path: "/e-sic", title: "e-SIC", keywords: ["e-sic", "esic", "acesso à informação", "transparência", "consulta pública", "solicitação de dados", "dados públicos"] },
        { path: "/prefeitura/carta-de-servico", title: "Carta de Serviço", keywords: ["carta de serviço", "cartadeservico", "carta", "carta de atendimento", "informações de serviço", "guias de serviço", "carta municipal de serviços", "serviços municipais"] },
        { path: "/secretarias/administracao", title: "Administração", keywords: ["admin", "gestão", "administração", "direção"] },
        { path: "/secretarias/assistenciasocial", title: "Assistência Social", keywords: ["social", "assistente", "apoio social", "ajuda social", "bem-estar"] },
        { path: "/secretarias/cultura", title: "Cultura e Turismo", keywords: ["cultura", "turismo", "eventos", "patrimônio", "turista"] },
        { path: "/secretarias/desenvolvimentorural", title: "Desenvolvimento Rural", keywords: ["rural", "agricultura", "campo", "desenvolvimento agrícola", "agronegócio"] },
        { path: "/secretarias/educacao", title: "Educação", keywords: ["escolas", "ensino", "educacao", "aprendizado", "instituições"] },
        { path: "/secretarias/financas", title: "Finanças", keywords: ["financeiro", "dinheiro", "contabilidade", "tesouraria", "financas"] },
        { path: "/secretarias/meioambiente", title: "Meio Ambiente", keywords: ["ambiente", "ecologia", "natureza", "sustentabilidade", "meio ambiente"] },
        { path: "/secretarias/saude", title: "Saúde", keywords: ["medico", "hospital", "saude", "enfermagem", "clínica"] },
        { path: "/setor/recursoshumanos", title: "Responsável pelo Setor", keywords: ["rh", "humanos", "gestão de pessoas", "recrutamento"] },
        { path: "/historia", title: "Historia", keywords: ["historia", "history"] },
        { path: "/legislacao/leismunicipais", title: "Leis Municipais", keywords: ["leis", "legislação", "municipal", "regras", "regulamento"] },
        { path: "/legislacao/aldir_blanc", title: "Lei Aldir Blanc Nº 14.017", keywords: ["aldir blanc", "lei cultura", "subvenção cultural", "apoio cultural", "financiamento cultural"] },
        { path: "/leipaulogustavo", title: "Lei Paulo Gustavo Nº 195/2022", keywords: ["paulo gustavo", "lei cultura", "cultura", "fomento cultural", "apoio financeiro"] },
        { path: "/legislacao/leiorganica", title: "Lei Orgânica", keywords: ["orgânica", "constituição", "fundamental", "regulamentação", "base legal"] },
        { path: "/legislacao/diariosoficial", title: "Diários Oficial", keywords: ["diário", "publicação", "informativo", "notícias oficiais", "documento público"] },
        { path: "/legislacao/codigotributario", title: "Código Tributário", keywords: ["tributário", "impostos", "taxas", "fiscal", "arrecadação"] },
        { path: "/legislacao/controleinterno", title: "Controle Interno Municipal", keywords: ["controle", "auditoria", "monitoramento", "fiscalização", "verificação"] },
        { path: "/app/iptu", title: "IPTU", keywords: ["imposto", "propriedade", "imovel", "iptu", "taxa de propriedade"] },
        { path: "/saladoempreendedor", title: "Sala do Empreendedor", keywords: ["empreendedorismo", "negócios", "empresa", "empreendedor", "microempresa"] },
        { path: "/licitacoes", title: "Licitações", keywords: ["licitacoes", "concorrência", "pregão", "processo licitatório"] },
        { path: "/avisos", title: "Avisos Sociais", keywords: ["avisos", "notificações", "comunicado", "informação pública", "noticias sociais"] },
        { path: "/noticias/todas", title: "Todas as Notícias", keywords: ["noticias", "noticia", "informações", "artigos", "reportagens", "novidades"] },
        { path: "/arquivos/loa", title: "LOA", keywords: ["loa", "orçamento", "orçamento anual", "lei orçamentária", "planejamento financeiro"] },
        { path: "/arquivos/ldo", title: "LDO", keywords: ["ldo", "diretrizes", "lei de diretrizes", "planejamento", "finanças públicas"] },
        { path: "/arquivos/pca", title: "PCA", keywords: ["pca", "Plano de Contratação Anual"] },
        { path: "/arquivos/rreo", title: "RREO", keywords: ["rreo", "relatório", "execução orçamentária", "balanço financeiro", "prestação de contas"] },
        { path: "/arquivos/rgf", title: "RGF", keywords: ["rgf", "gestão fiscal", "responsabilidade fiscal", "balanço", "demonstrativo fiscal"] },
        { path: "/arquivos/quadro", title: "Quadro Funcional", keywords: ["funcionários", "funcional", "quadro", "folha de pagamento", "servidores", "empregados", "pessoal"] },
        { path: "/arquivos/contratos", title: "Contratos", keywords: ["contratos", "acordos", "convênios", "parcerias", "documentos legais"] },
        { path: "/legislacao/decretos", title: "Decretos", keywords: ["decreto", "normas", "regulamentação", "ordem executiva", "diretrizes"] },
        { path: "/arquivos/editais", title: "Editais", keywords: ["editais", "avisos", "concursos", "publicações", "anúncios oficiais"] },
        { path: "/arquivos/farmaciabasica", title: "Estoque Fármacia Básica", keywords: ["farmácia", "remedio", "estoque", "remédios", "medicamentos", "saúde pública"] },
        { path: "/arquivos/frota", title: "Frota Municipal", keywords: ["veículos", "frota", "transporte", "carros oficiais", "automóveis"] },
        { path: "/arquivos/tabela-diarias", title: "Diarias", keywords: ["diarias", "tabela diaria", "tabela diarias", "diária"] },
        { path: "/legislacao/portarias", title: "Portarias", keywords: ["portaria", "regulamento", "diretiva", "instrução", "normativa"] },
        { path: "/processo_seletivo", title: "Processo Seletivo", keywords: ["seleção", "vagas", "concurso", "recrutamento", "oportunidades"] },
        { path: "/rh/cc", title: "Contra-cheque", keywords: ["contra cheque", "contra-cheque", "holerite", "salário"] },
        { path: "/rh/documentos", title: "Requerimentos", keywords: ["pedido", "solicitações", "requisição", "solicitar", "ordem", "requerer", "formulários", "solicitar", "pedido", "requisição"] },
        { path: "/rh/documentos", title: "Documentos Baixáveis", keywords: ["baixar", "documentos", "downloads", "arquivos", "impressões"] },
        { path: "/rh/fichafuncional", title: "Ficha Funcional", keywords: ["funcionario", "ficha", "perfil", "registro", "histórico"] },
      ];

      const searchKeywords = searchTerm.toLowerCase();
      const contactKeywords = ["contato", "telefone", "email"];
      const isContactSearch = contactKeywords.some((keyword) => searchKeywords.includes(keyword));
      if (isContactSearch) {
        setLoading(false);
        setSearchTerm('');
        return;
      }

      const socialMediaKeywords = {
        facebook: ["facebook", "gestão no facebook"],
        instagram: ["instagram", "gestão no instagram"],
        youtube: ["youtube", "gestão no youtube", "canal no youtube"],
        whatsapp: ["whatsapp", "gestão no whatsapp", "contato whatsapp"],
        imagens: ["imagens aereas", "drone", "imagem aerea", "aerea"],
      };

      if (socialMediaKeywords.facebook.some((keyword) => searchKeywords.includes(keyword))) {
        window.open(`${config.links.facebook}`, "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }

      if (socialMediaKeywords.instagram.some((keyword) => searchKeywords.includes(keyword))) {
        window.open(`${config.links.instagram}`, "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }

      if (socialMediaKeywords.youtube.some((keyword) => searchKeywords.includes(keyword))) {
        window.open(`${config.links.youtube}`, "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }

      if (socialMediaKeywords.imagens.some((keyword) => searchKeywords.includes(keyword))) {
        window.open("https://www.youtube.com/watch?v=JoRDqdBEsP0", "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }

      if (socialMediaKeywords.whatsapp.some((keyword) => searchKeywords.includes(keyword))) {
        window.open("https://wa.me/5583982329224", "_blank");
        setLoading(false);
        setSearchTerm('');
        return;
      }

      const matchedLink = headerLinks.find(
        (link) =>
          link.title.toLowerCase().includes(searchKeywords) ||
          link.keywords.some((keyword) => searchKeywords.includes(keyword))
      );

      if (matchedLink) {
        navigate(matchedLink.path);
        setLoading(false);
        setSearchTerm('');
        return;
      }

      try {
        const wikipediaSummary = await searchWikipedia(searchTerm);
        setDialogContent(wikipediaSummary || "Nenhum resultado encontrado. Tente uma nova busca.");
        setShowDialog(true);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar informação:", error);
        setDialogContent("Não foi possível buscar a informação. Tente novamente mais tarde.");
        setShowDialog(true);
        setLoading(false);
      }
    }, 500);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchWikipediaSummary = async (title) => {
    const url = `https://pt.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.extract) {
        const lowerCaseExtract = data.extract.toLowerCase();
        if (lowerCaseExtract.includes(cidade) || lowerCaseExtract.includes(cidade)) {
          return data.extract;
        } else {
          return "Nenhuma informação relevante encontrada. Tente outra consulta.";
        }
      } else {
        return "Nenhuma informação relevante encontrada. Tente outra consulta.";
      }
    } catch (error) {
      console.error("Erro ao buscar dados na Wikipedia:", error);
      return "Ocorreu um erro ao buscar informações.";
    }
  };

  const searchWikipedia = async (query) => {
    const url = `https://pt.wikipedia.org/w/api.php?action=query&list=search&srsearch=${encodeURIComponent(query)}&format=json&origin=*`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.query.search && data.query.search.length > 0) {
        const pageTitle = data.query.search[0].title;
        return await fetchWikipediaSummary(pageTitle);
      } else {
        return "Nenhuma informação relevante encontrada. Tente outra consulta.";
      }
    } catch (error) {
      console.error("Erro ao buscar dados na Wikipedia:", error);
      return "Ocorreu um erro ao buscar informações.";
    }
  };

  const renderSubMenuItems = (items) =>
    (items || []).map((item, index) => (
      <MenuItem
        key={index}
        component={Link}
        to={item.link}
        onClick={() => {
          closeSubMenu();
          setDrawerOpen(false);
        }}
        sx={{
          pl: 4,
          color: isMobile ? 'white' : 'black',
          '&:hover': {
            backgroundColor: isMobile ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        {item.name}
      </MenuItem>
    ));

  const renderDrawerMenu = () => (
    <Box role="presentation" sx={{ width: 250, background: config.background, height: '100%' }}>
      <List>
        <Link to={'/'} onClick={() => setDrawerOpen(false)}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Início" sx={{ color: 'white' }} />
          </ListItem>
        </Link>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />

        {dynamicMenus.map((menu, index) => (
          menu.title && menu.icon && (
            <React.Fragment key={index}>
              {(menu.items || []).length > 0 ? (
                <>
                  <ListItem button onClick={() => toggleSubMenu(null, menu.title.toLowerCase())}>
                    <ListItemIcon>
                      {MuiIcons[menu.icon] ? React.createElement(MuiIcons[menu.icon], { sx: { color: 'white' } }) : null}
                    </ListItemIcon>
                    <ListItemText primary={menu.title} sx={{ color: 'white' }} />
                    <ExpandMoreIcon sx={{ color: 'white' }} />
                  </ListItem>
                  <Collapse in={subMenuOpen[menu.title.toLowerCase()]} timeout={150} unmountOnExit>
                    {renderSubMenuItems(menu.items)}
                  </Collapse>
                </>
              ) : (
                <Link to={menu.link || "#"} onClick={() => setDrawerOpen(false)}>
                  <ListItem button>
                    <ListItemIcon>
                      {MuiIcons[menu.icon] ? React.createElement(MuiIcons[menu.icon], { sx: { color: 'white' } }) : null}
                    </ListItemIcon>
                    <ListItemText primary={menu.title} sx={{ color: 'white' }} />
                  </ListItem>
                </Link>
              )}
            </React.Fragment>
          )
        ))}
      </List>
    </Box>
  );

  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
    if (searchOpen) {
      setSearchTerm('');
    }
  };

  return (
    <>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          Resultado da Pesquisa
          <IconButton
            aria-label="close"
            onClick={() => setShowDialog(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
      </Dialog>

      <AppBar position="sticky" sx={{ background: config.background, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)' }}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', minHeight: { xs: 56, sm: 64 } }}>
          {isMobile && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ fontSize: 30 }} />
            </IconButton>
          )}

          <Link to={'/'}>
            <Logo
              src={config?.logo || 'https://kenekt-cdn.imgix.net/tvz5ih9ce0gbj652p4i5q2xpbq8u?ixlib=rails-4.3.1'}
              alt="Logo"
              sx={{
                marginY: 1,
                marginLeft: isMobile ? 1 : 2,
                maxWidth: config?.logoSize + 'px',
              }}
            />
          </Link>

          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: 3 }}>
              <Link to={'/'}>
                <Button
                  color="inherit"
                  startIcon={<HomeIcon />}
                  sx={{
                    fontWeight: 'bold',
                    color: '#ffffff',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: fontSize,
                    textTransform: 'none',
                    padding: '6px 12px',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                  }}
                >
                  Início
                </Button>
              </Link>

              {dynamicMenus.map((menu, index) => (
                menu.title && menu.icon && (
                  <React.Fragment key={index}>
                    {(menu.items || []).length > 0 ? (
                      <>
                        <Button
                          color="inherit"
                          startIcon={MuiIcons[menu.icon] ? React.createElement(MuiIcons[menu.icon]) : null}
                          endIcon={<ExpandMoreIcon />}
                          onClick={(event) => toggleSubMenu(event, menu.title.toLowerCase())}
                          sx={{
                            fontWeight: 'bold',
                            fontFamily: 'Roboto, sans-serif',
                            fontSize: fontSize,
                            textTransform: 'none',
                            padding: '6px 12px',
                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                          }}
                        >
                          {menu.title}
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(subMenuOpen[menu.title.toLowerCase()]) && anchorEl}
                          onClose={closeSubMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 100 }}
                          PaperProps={{
                            sx: {
                              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                              borderRadius: '4px',
                            },
                          }}
                        >
                          {renderSubMenuItems(menu.items)}
                        </Menu>
                      </>
                    ) : (
                      <Button
                        color="inherit"
                        component={Link}
                        to={menu.link || "#"}
                        startIcon={MuiIcons[menu.icon] ? React.createElement(MuiIcons[menu.icon]) : null}
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Roboto, sans-serif',
                          fontSize: fontSize,
                          textTransform: 'none',
                          padding: '6px 12px',
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                        }}
                      >
                        {menu.title}
                      </Button>
                    )}
                  </React.Fragment>
                )
              ))}
            </Box>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {!isMobile && (
            <Collapse in={searchOpen} timeout="auto" orientation="horizontal" sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              <Box
                component="form"
                onSubmit={handleSearchSubmit}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Pesquise aqui..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="submit"
                          edge="end"
                          sx={{
                            padding: '3px',
                            color: '#333',
                          }}
                        >
                          {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: { sm: '200px', md: '250px' },
                    backgroundColor: '#f1f1f1',
                    borderRadius: 1,
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '20px',
                      paddingLeft: '8px',
                      height: '40px',
                    },
                  }}
                />
                <IconButton
                  onClick={handleSearchToggle}
                  sx={{
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#1d1d1d',
                      color: 'white',
                    },
                    marginLeft: 1,
                    padding: '6px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Collapse>
          )}

          {!searchOpen && (
            <IconButton
              onClick={handleSearchToggle}
              sx={{
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#1d1d1d',
                  color: 'white',
                },
                marginLeft: 'auto',
                padding: '8px',
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Toolbar>

        {isMobile && (
          <Collapse in={searchOpen} timeout="auto" sx={{ marginTop: 1, display: 'flex', justifyContent: 'center' }}>
            <Box
              component="form"
              onSubmit={handleSearchSubmit}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '90%',
                margin: '0 auto',
                paddingBottom: 1,
              }}
            >
              <IconButton
                onClick={handleSearchToggle}
                sx={{
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: '#1d1d1d',
                    color: 'white',
                  },
                  marginRight: 1,
                  padding: '6px',
                }}
              >
                <CloseIcon />
              </IconButton>

              <TextField
                variant="outlined"
                placeholder="Pesquise aqui..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        edge="end"
                        sx={{
                          padding: '3px',
                          color: '#333',
                        }}
                      >
                        {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '100%',
                  backgroundColor: '#f1f1f1',
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    paddingRight: '20px',
                    paddingLeft: '8px',
                    height: '40px',
                  },
                }}
              />
            </Box>
          </Collapse>
        )}

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {renderDrawerMenu()}
        </Drawer>
      </AppBar>
    </>
  );
}