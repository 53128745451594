import React, { useEffect, useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Slide, Button, Skeleton, Fade, Box, Divider, Collapse, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { getDatabase, ref, get, query, orderByChild, limitToLast } from 'firebase/database';
import firebaseApp, { camara, KEY_REF } from './adm/admin/firebaseConfig';
import { useTheme } from '@mui/material/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function removePTags(html) {
  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

export default function Noticias({ config }) {
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Menor que 960px
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Menor que 600px

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    const fetchNoticias = async () => {
      setLoading(true);
      if (!KEY_REF) {
        console.error("KEY_REF não definido.");
        setLoading(false);
        return;
      }
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = query(ref(databaseInstance, `noticias`), orderByChild('data'), limitToLast(8));
        const snapshot = await get(noticiasRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const noticiasArray = Object.keys(data).map((key) => ({
            id: key,
            titulo: data[key].titulo,
            descricao: data[key].descricao,
            imagem: data[key].imagem,
            data: data[key].data,
          }));
          setNoticias(noticiasArray.reverse());
        } else {
          setNoticias([]);
        }
      } catch (error) {
        console.error("Erro ao buscar notícias:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchNoticias();
  }, [KEY_REF]);

  const renderSkeleton = (index) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
      <Skeleton variant="rectangular" height={isVerySmallScreen ? 150 : isSmallScreen ? 200 : 300} />
      <Skeleton variant="text" />
      <Skeleton variant="text" width="60%" />
    </Grid>
  );

  const renderNoticias = (noticia) => {
    const descricaoSemPTags = removePTags(noticia.descricao);
    const tituloParaURL = noticia.titulo
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, '-')
      .toLowerCase();
    const isExpanded = expanded[noticia.id];

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={noticia.id}>
        <Fade in={!loading} timeout={1000}>
          <Card
            sx={{
              boxShadow: 3,
              minHeight: isVerySmallScreen ? 250 : isSmallScreen ? 300 : 400,
              maxHeight: isExpanded ? 'auto' : isVerySmallScreen ? 300 : isSmallScreen ? 350 : 450,
              '&:hover': { boxShadow: 6 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'hidden',
              transition: 'max-height 0.3s ease, transform 0.2s ease',
            }}
          >
            <Link to={`/noticias/${tituloParaURL}/${noticia.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <CardMedia
                component="img"
                sx={{
                  height: isVerySmallScreen ? 120 : isSmallScreen ? 150 : 200,
                  objectFit: 'cover',
                  width: '100%',
                }}
                image={noticia.imagem}
                alt={noticia.titulo}
              />
            </Link>
            <CardContent sx={{ flex: '1 1 auto', overflow: 'hidden', padding: isVerySmallScreen ? 1 : 2 }}>
              <Link to={`/noticias/${tituloParaURL}/${noticia.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography
                  fontSize={isVerySmallScreen ? 14 : isSmallScreen ? 16 : 18}
                  sx={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    marginBottom: '10px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '0px',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {noticia.titulo}
                </Typography>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      textAlign: 'justify',
                      wordBreak: 'break-word',
                      fontSize: isVerySmallScreen ? 10 : isSmallScreen ? 12 : 14,
                    }}
                  >
                    {descricaoSemPTags.length > 600 ? `${descricaoSemPTags.slice(0, 600)}[...]` : descricaoSemPTags}
                  </Typography>
                </Collapse>
              </Link>
              {!isExpanded && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: isVerySmallScreen ? 2 : isSmallScreen ? 3 : 4,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textAlign: 'justify',
                    wordBreak: 'break-word',
                    fontSize: isVerySmallScreen ? 10 : isSmallScreen ? 12 : 14,
                  }}
                >
                  {descricaoSemPTags.length > 150 ? `${descricaoSemPTags.slice(0, 150)}...` : descricaoSemPTags}
                </Typography>
              )}
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                {descricaoSemPTags.length > 150 && (
                  <>
                    {isExpanded && (
                      <Link to={`/noticias/${tituloParaURL}/${noticia.id}`}>
                        <Button
                          size="small"
                          component="a"
                          target="_blank"
                          sx={{
                            fontWeight: 'bold',
                            fontSize: isVerySmallScreen ? 10 : isSmallScreen ? 12 : 14,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.3,
                            textTransform: 'none',
                            padding: '4px 5px',
                            color: 'black !important',
                          }}
                          rel="noopener noreferrer"
                        >
                          <LaunchIcon sx={{ fontSize: isVerySmallScreen ? 14 : isSmallScreen ? 16 : 18, marginRight: 0.2, color: config?.color }} />
                          Abrir Matéria
                        </Button>
                      </Link>
                    )}
                    <Button
                      size="small"
                      onClick={() => handleExpandClick(noticia.id)}
                      sx={{
                        fontWeight: 'bold',
                        fontSize: isVerySmallScreen ? 10 : isSmallScreen ? 12 : 14,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.3,
                        textTransform: 'none',
                        padding: '4px 5px',
                        color: config?.color,
                      }}
                    >
                      {isExpanded ? (
                        <>
                          <ExpandLessIcon sx={{ fontSize: isVerySmallScreen ? 14 : isSmallScreen ? 16 : 18 }} />
                          Mostrar Menos
                        </>
                      ) : (
                        <>
                          <ExpandMoreIcon sx={{ fontSize: isVerySmallScreen ? 14 : isSmallScreen ? 16 : 18 }} />
                          Leia Mais
                        </>
                      )}
                    </Button>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Fade>
      </Grid>
    );
  };

  const renderUltimasNoticias = () => {
    const ultimasNoticias = noticias.slice(0, 8);
    return loading ? [...Array(8)].map((_, index) => renderSkeleton(index)) : ultimasNoticias.map(renderNoticias);
  };

  const renderServiceCard = (service) => {
    if (!service.active) return null;

    const linkProps = service.external
      ? { component: 'a', href: service.href, target: '_blank', rel: 'noopener noreferrer' }
      : { to: service.href };

    if (!service.href) {
      console.warn(`Href vazio ou ausente para o serviço: ${service.name}`);
    }

    return (
      <Grid item xs={6} sm={4} md={6} key={service.name} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card
          sx={{
            boxShadow: 3,
            '&:hover': { boxShadow: 6 },
            textAlign: 'center',
            padding: 2,
            height: isVerySmallScreen ? '80px' : isSmallScreen ? '100px' : '140px',
            width: isVerySmallScreen ? '80px' : isSmallScreen ? '100px' : '140px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link {...linkProps} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardMedia
              component="img"
              image={`/imagens/icons/${service.icon}`}
              alt={service.name}
              sx={{
                width: isVerySmallScreen ? 40 : isSmallScreen ? 50 : 70,
                height: isVerySmallScreen ? 40 : isSmallScreen ? 50 : 70,
                marginTop: 3.2,
              }}
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: isVerySmallScreen ? '0.6em' : isSmallScreen ? '0.7em' : '0.85em',
                  textAlign: 'center',
                }}
              >
                {service.name}
              </Typography>
            </CardContent>
          </Link>
        </Card>
      </Grid>
    );
  };

  const acessoRapidoData = (config?.acessoRapido || []).filter((service) => service.active);

  return (
    <Box sx={{ backgroundColor: '#f9f9f9', paddingTop: 5, paddingBottom: 5, paddingX: { xs: 1, sm: 2, md: 10 } }}>
      <Grid container spacing={isVerySmallScreen ? 1 : isSmallScreen ? 1.5 : 2.5}>
        <Grid item xs={12} md={config?.design?.acessoRapido ? 10 : 12}>
          <Box textAlign="center" mb={4}>
            <Typography
              sx={{
                fontSize: isVerySmallScreen ? 20 : isSmallScreen ? 24 : 28,
                color: config.color,
                fontWeight: 'bold',
              }}
              gutterBottom
            >
              FIQUE INFORMADO
            </Typography>
            <Divider
              sx={{
                backgroundColor: config?.color,
                height: 2,
                marginBottom: 4,
                marginX: 'auto',
                width: { xs: '50%', md: '80%' },
              }}
            />
          </Box>
          <Grid container spacing={isVerySmallScreen ? 1 : isSmallScreen ? 1.5 : 2.5} sx={{ paddingX: { xs: 1, md: 10 } }}>
            {renderUltimasNoticias()}
          </Grid>
        </Grid>
        {config?.design?.acessoRapido && (
          <Grid item xs={12} md={2}>
            <Box textAlign="center" mb={4}>
              <Typography
                sx={{
                  fontSize: isVerySmallScreen ? 20 : isSmallScreen ? 24 : 28,
                  color: config.color,
                  fontWeight: 'bold',
                }}
                gutterBottom
              >
                ACESSO RÁPIDO
              </Typography>
              <Divider sx={{ backgroundColor: config?.color, height: 2, marginBottom: 3 }} />
            </Box>
            <Grid container spacing={isVerySmallScreen ? 0.5 : 1}>
              {acessoRapidoData.map(renderServiceCard)}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box mt={2} textAlign="center">
        <Link to="/noticias/todas">
          <Button
            variant="contained"
            size="medium"
            startIcon={<LaunchIcon />}
            sx={{
              backgroundColor: config?.color,
              color: '#FFFFFF',
              textTransform: 'uppercase',
              '&:hover': { backgroundColor: config?.colorSecondary, color: config?.color },
              padding: isVerySmallScreen ? '6px 16px' : '8px 20px',
              borderRadius: '30px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              fontWeight: 'bold',
              letterSpacing: '0.5px',
              fontSize: isVerySmallScreen ? 12 : 14,
            }}
          >
            TODAS AS NOTÍCIAS
          </Button>
        </Link>
      </Box>
    </Box>
  );
}