import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button, Grid, IconButton, Collapse, useMediaQuery, Divider
} from '@mui/material';
import { ExpandMore, FileDownload, Handshake, NotificationImportant } from '@mui/icons-material';
import { getDatabase, ref, query, orderByChild, equalTo, limitToLast, get } from "firebase/database";
import firebaseApp, { camara, database, KEY_REF } from './adm/admin/firebaseConfig';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TitleSection from './utils/TittleTheme';
import CardBodeNaRua from './utils/CardBodeNaRua';

function removePTags(html) {
  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}

const fetchData = async (setNoticias, setAvisos) => {
  try {
    const noticiasRef = query(
      ref(database, `licitacoes`),
      orderByChild("ativo"),
      equalTo(true),
      limitToLast(5)
    );

    const noticiasSnapshot = await get(noticiasRef);
    const noticiasData = noticiasSnapshot.val();

    if (noticiasData) {
      const noticiasArray = Object.keys(noticiasData).map((key) => ({
        id: key,
        date: noticiasData[key].data,
        titulo: noticiasData[key].titulo,
        descricao: noticiasData[key].descricao,
        numero: noticiasData[key].numero,
        horario: noticiasData[key].horario || "",
        modalidade: noticiasData[key].modalidade,
        documentoUrl: noticiasData[key].documentoUrl,
      })).sort((a, b) => new Date(b.date) - new Date(a.date));

      setNoticias(noticiasArray);
    }

    const avisosRef = query(
      ref(database, `avisos_sociais`),
      orderByChild("ativo"),
      equalTo(true),
      limitToLast(5)

    );

    const avisosSnapshot = await get(avisosRef);
    const avisosData = avisosSnapshot.val();

    if (avisosData) {
      const avisosArray = Object.keys(avisosData).map((key) => ({
        id: key,
        date: avisosData[key].data,
        titulo: avisosData[key].titulo,
        descricao: avisosData[key].descricao,
        documentoUrl: avisosData[key].documentoUrl,
      })).sort((a, b) => new Date(b.date) - new Date(a.date));

      setAvisos(avisosArray);
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default function Novidades({ config }) {
  const [noticias, setNoticias] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [expanded, setExpanded] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchData(setNoticias, setAvisos);
  }, []);

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const renderListItem = (item, icon) => (
    <Box key={item.id} sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1, marginBottom: 2 }}>
      <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"} justifyContent="space-between">
        <Grid item xs={isMobile ? 12 : 5}>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" sx={{ marginRight: isMobile ? 0 : 1 }}>
              {React.isValidElement(icon) ? icon : <NotificationImportant />}
            </IconButton>


            <Typography
              variant={isMobile ? "body1" : "body1"}
              fontWeight="bold"
              sx={{
                fontSize: isMobile ? '1rem' : '1rem',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
              }}
            >
              {truncateText(item.titulo, 80)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isMobile ? 12 : 2}>
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: isMobile ? 'left' : 'center' }}>
            {item.date}
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <Box display={isMobile ? 'flex' : 'flex'} flexDirection={isMobile ? 'row' : 'row'} justifyContent={isMobile ? 'space-between' : 'center'} mt={isMobile ? 1 : 0}>
            {(item.documentoUrl != null && item.documentoUrl !== "") && (
              <Button
                href={item.documentoUrl}
                target="_blank"
                variant="contained"
                size="small"
                startIcon={<FileDownload />}
                sx={{
                  textTransform: 'uppercase', fontSize: isMobile ? '0.8rem' : '0.875rem', minWidth: isMobile ? '45%' : '100px',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white'
                  },
                }}
              >
                Baixar
              </Button>
            )}


            <Button
              variant="outlined"
              size="small"
              onClick={() => handleExpandClick(item.id)}
              endIcon={<ExpandMore />}
              sx={{ color: config?.color, fontWeight: 'bold', textTransform: 'uppercase', fontSize: isMobile ? '0.8rem' : '0.875rem', minWidth: isMobile ? '45%' : '100px', ml: isMobile ? 1 : 1 }}
            >
              Detalhes
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
        <Box sx={{ padding: 2, mt: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
          {item.horario && <Typography variant="body2"><strong>Horário:</strong> {item.horario}</Typography>}
          {item.numero && <Typography variant="body2"><strong>Número:</strong> {item.numero}</Typography>}
          {item.modalidade && <Typography variant="body2"><strong>Modalidade:</strong> {item.modalidade}</Typography>}
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <strong>Descrição:</strong> {removePTags(item.descricao)}
          </Typography>
        </Box>
      </Collapse>
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );

  const renderContent = (data, title, subtitle, icon, link) => (
    <Box sx={{ py: 2, backgroundColor: '#f5f5f5', maxWidth: '1400px', mx: 'auto' }}>
      <TitleSection config={config} title={title} subtitle={subtitle} link={link} todos={true} share={true} />
      {Array.isArray(data) && data.length > 0 ? (
        data.slice(0, 6).map(item => renderListItem(item, icon))
      ) : (
        <Typography fontSize={19} align="center" sx={{ color: '#888', mt: 2 }}>
          Não há nenhuma informação a ser mostrada
        </Typography>
      )}

    </Box>

  );

  return (
    <Box sx={{ padding: 1, paddingLeft: { xs: 3, md: 15 }, paddingRight: { xs: 3, md: 15 } }}>

      {config?.design?.avisos &&

        <>
          {renderContent(
            avisos,
            "NOTAS, COMUNICADOS E EVENTOS",
            "Mantenha-se informado por dentro das últimos comunicados, notas, novidades e eventos importantes em nossa comunidade.",
            <NotificationImportant sx={{ fontSize: 30 }} />,
            "/avisos"
          )}
        </>
      }

      {!camara && KEY_REF === '2506509' && <CardBodeNaRua />}
      {config?.design?.licitacoes
        &&
        <>
          {!camara && <>
            {renderContent(
              noticias,
              "PRÓXIMAS LICITAÇÕES",
              "Mantenha-se informado sobre as próximas licitações, marcando presença nas datas importantes.",
              <Handshake sx={{ fontSize: 30, color: config?.color }} />,
              "/licitacoes"
            )}</>}
        </>
      }

    </Box>
  );
}
